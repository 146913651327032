import { Paddix } from "@paddix/paddix-core";
import multiTenantPlugin from "@paddix/paddix-multi-tenant-plugin";
import contentPlugin from "@paddix/paddix-content-plugin";
import imagePlugin from "@paddix/paddix-image-plugin";
import publishingPlugin from "@paddix/paddix-publishing-plugin";
import edaCrac2022Plugin from "@paddix/paddix-eda-crac-2022-plugin";

Paddix.use(multiTenantPlugin)
  .use(contentPlugin)
  .use(imagePlugin)
  .use(publishingPlugin)
  .use(edaCrac2022Plugin)
  .start();
